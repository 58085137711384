import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import apiKey from '../utils/axiosInstance';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
// ----------------------------------------------------------------------
// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...
// ----------------------------------------------------------------------
var Types;
(function (Types) {
    Types["INITIAL"] = "INITIAL";
    Types["LOGIN"] = "LOGIN";
    Types["REGISTER"] = "REGISTER";
    Types["LOGOUT"] = "LOGOUT";
    Types["FORGET"] = "FORGET";
    Types["RESET"] = "RESET";
    Types["UPDATE_PASSWORD"] = "UPDATE_PASSWORD";
})(Types || (Types = {}));
// ----------------------------------------------------------------------
const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
};
const reducer = (state, action) => {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === Types.REGISTER) {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGOUT) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }
    if (action.type === Types.FORGET) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }
    if (action.type === Types.UPDATE_PASSWORD) {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    return state;
};
// ----------------------------------------------------------------------
export const AuthContext = createContext(null);
export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const storageAvailable = localStorageAvailable();
    const initialize = useCallback(async () => {
        try {
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);
                const response = await apiKey.get('/profile/me');
                const user = response.data.data;
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                });
            }
            else {
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        }
        catch (error) {
            console.error(error);
            dispatch({
                type: Types.INITIAL,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, [storageAvailable]);
    useEffect(() => {
        initialize();
    }, [initialize]);
    // LOGIN
    const login = useCallback(async (email, password) => {
        const response = await apiKey.post('/login', {
            email,
            password,
        });
        const { tokens, user } = response.data.data;
        setSession(tokens.accessToken);
        dispatch({
            type: Types.LOGIN,
            payload: {
                user,
            },
        });
    }, []);
    // REGISTER
    const register = useCallback(async (email, password, firstName, lastName) => {
        const response = await apiKey.post('/api/account/register', {
            email,
            password,
            firstName,
            lastName,
        });
        const { accessToken, user } = response.data;
        localStorage.setItem('accessToken', accessToken);
        dispatch({
            type: Types.REGISTER,
            payload: {
                user,
            },
        });
    }, []);
    // LOGOUT
    const logout = useCallback(async () => {
        await apiKey.delete('/logout');
        setSession(null);
        dispatch({
            type: Types.LOGOUT,
        });
    }, []);
    // FORGET PASSWORD
    const forgetPassword = useCallback(async (email) => {
        await apiKey.post(`/forgot-password`, {
            email,
        });
        dispatch({
            type: Types.FORGET,
        });
    }, []);
    // FORGET PASSWORD
    const resetPassword = useCallback(async (password, token) => {
        await apiKey.put(`/reset-password/${token}`, {
            password,
        });
        dispatch({
            type: Types.RESET,
        });
    }, []);
    // UPDATE PASSWORD
    const updatePassword = useCallback(async (password, newPassword) => {
        const response = await apiKey.put('/update-password', {
            password,
            newPassword,
        });
        const { tokens, user } = response.data.data;
        setSession(tokens.accessToken);
        dispatch({
            type: Types.UPDATE_PASSWORD,
            payload: {
                user,
            },
        });
    }, []);
    const memoizedValue = useMemo(() => ({
        isInitialized: state.isInitialized,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        method: 'jwt',
        login,
        loginWithGoogle: () => { },
        loginWithGithub: () => { },
        loginWithTwitter: () => { },
        register,
        logout,
        forgetPassword,
        resetPassword,
        updatePassword,
    }), [
        state.isAuthenticated,
        state.isInitialized,
        state.user,
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        updatePassword,
    ]);
    return _jsx(AuthContext.Provider, { value: memoizedValue, children: children });
}
